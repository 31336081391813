import React from "react"

import { AdminLayout } from "components/layout-custom"
import TripsAdmin from "components/trips"

const Page = () => (
  <AdminLayout title="Trips" padded={false}>
    <TripsAdmin allowAdminActions={true} />
  </AdminLayout>
)

export default Page
